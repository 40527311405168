.centered-text {
    text-align: center;
    height: 40%;
  }
  
  .contact-section {
    padding-left:0px;
  }
  
  .formss {
    justify-content: center;
    text-align: center;
  }
  
  .formss form {
    display: inline-block;
    text-align: left;
  }
  
  .formss label {
    display: block;
    margin-bottom: 10px;
  }
  
  .formss input,
  .formss textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 2px solid;
    font-size: large;
  }
  
  .formss button {
    padding: 10px 20px;
    width: 100%;
    border: 2px solid;
    font-size: large;
  }
  
  .importantcontacts {
    margin: auto;
  }
  
  .row {
    display: flex;
    align-items: center;
    padding-bottom: 2%;
  }
  
  .location {
    display: "flex";
    justify-content: center;
    margin: "auto";
  }
  
  
  .downcontent {
    width: 100%;
    background-color: #D9D9D9;
    height: 40vh;
  }
  
  .paragraph {
    text-align: center;
  }
  
  .label {
    display: flex;
    margin: auto;

  }
  

  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact-section,
    .importantcontacts,
    .location,
    .gallery {
      padding-left: 10px;
    }
  
    .formss {
      padding: 0 10px;
    }
  
    .gallery {
      width: 100%;
      height: auto;
    }
  
    .gallery img {
      width: 100%;
      height: auto;
    }
  }
