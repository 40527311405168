.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.team-row {
    display: flex;
    justify-content: space-between;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    text-align: center;
    padding: 2%;
  }
  
  .member-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .team-grid {
    padding: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .role {
    margin-top: 0;
  }

  .button-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3%;
  }

  .button-container {
    padding: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    text-align: center;
    background-color: #D9D9D9;
    border: 0;
    padding-top:6%;
    padding-bottom: 6%;
    padding-left: 8%;
    padding-right: 8%;
    font-size: 170%;
  }
  button:hover {
    background-color: #a9a8a8;
    cursor: pointer;
  }
  
  a {
    text-decoration: none;
  }

  h2 {
    padding-left: 12%;
    font-size: 250%;
    font-weight: 400;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    button {
        font-size: 100%;
        padding: 6% 8% 6% 8%;
        align-items: center;
        text-align: center;
    }
  }

  .swiper-img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

}